import React from 'react';
import { Card, CardContent, Typography, Box, alpha } from '@mui/material';
import { ICoreService } from '../services/apiServices';
import SecurityIcon from '@mui/icons-material/Security';
import CloudIcon from '@mui/icons-material/Cloud';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import BusinessIcon from '@mui/icons-material/Business';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import StorageIcon from '@mui/icons-material/Storage';
import CodeIcon from '@mui/icons-material/Code';

interface ServicesCardProps extends ICoreService {
  index: number; 
  total?: number;
}

// Expanded icon map with more options
const iconMap: { [key: string]: React.ReactNode } = {
  'Security': <SecurityIcon sx={{ fontSize: 40 }} />,
  'Cloud': <CloudIcon sx={{ fontSize: 40 }} />,
  'SmartToy': <SmartToyIcon sx={{ fontSize: 40 }} />,
  'Business': <BusinessIcon sx={{ fontSize: 40 }} />,
  'Support': <SupportAgentIcon sx={{ fontSize: 40 }} />,
  'Analytics': <AnalyticsIcon sx={{ fontSize: 40 }} />,
  'Database': <StorageIcon sx={{ fontSize: 40 }} />,
  'Code': <CodeIcon sx={{ fontSize: 40 }} />
};

const ServicesCard: React.FC<ServicesCardProps> = ({ title, icon, items, index, total = 4 }) => {
  // Use a consistent color from our theme for all cards instead of alternating colors
  const primaryColor = '#2C3E50'; // --color-primary
  const secondaryColor = '#16A085'; // --color-secondary

  return (
    <Card 
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s, box-shadow 0.3s',
        borderRadius: '8px',
        overflow: 'hidden',
        border: '1px solid #E5E9F0',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
        }
      }}
      elevation={2}
    >
      <Box 
        sx={{ 
          bgcolor: primaryColor,
          p: 2.5,
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          color: 'white',
          position: 'relative',
          overflow: 'hidden',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            width: '30%',
            background: `linear-gradient(135deg, transparent 0%, ${alpha(secondaryColor, 0.2)} 100%)`,
            zIndex: 1
          }
        }}
      >
        <Box 
          sx={{
            bgcolor: alpha('#fff', 0.15),
            borderRadius: '50%',
            p: 1.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {iconMap[icon] || <BusinessIcon sx={{ fontSize: 40 }} />}
        </Box>
        <Typography 
          variant="h5" 
          component="h3"
          sx={{ 
            fontWeight: 600,
            color: 'white',
            zIndex: 2
          }}
        >
          {title}
        </Typography>
      </Box>
      <CardContent sx={{ 
        flexGrow: 1, 
        p: 3, 
        bgcolor: '#fff'
      }}>
        <Box component="ul" sx={{ 
          pl: 2, 
          mb: 0,
          '& li': {
            mb: 1.5,
            position: 'relative',
            pl: 1,
            '&::before': {
              content: '"•"',
              position: 'absolute',
              left: -16,
              color: secondaryColor,
              fontWeight: 'bold'
            },
            '&:last-child': {
              mb: 0
            }
          } 
        }}>
          {items.map((item) => (
            <Typography 
              component="li" 
              key={item}
              variant="body1"
              sx={{ lineHeight: 1.6 }}
            >
              {item}
            </Typography>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ServicesCard;
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close mobile menu when clicking on a navigation item
  const closeMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  // Check if the path matches the current location
  const isActive = (path: string) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <header className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link to="/" onClick={closeMenu}>
            <span className="logo-text">Candor House</span>
          </Link>
        </div>

        {/* Mobile menu toggle */}
        <button 
          className="mobile-menu-button" 
          onClick={toggleMenu}
          aria-label="Toggle menu"
          aria-expanded={isMenuOpen}
        >
          <span className="menu-icon"></span>
          <span className="menu-icon"></span>
          <span className="menu-icon"></span>
        </button>

        {/* Navigation links */}
        <nav className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
          <ul>
            <li>
              <Link 
                to="/" 
                className={isActive('/')} 
                onClick={closeMenu}
              >
                Home
              </Link>
            </li>
            <li>
              <Link 
                to="/biographies" 
                className={isActive('/about')} 
                onClick={closeMenu}
              >
                About
              </Link>
            </li>
            <li>
              <Link 
                to="/portfolios" 
                className={isActive('/features')} 
                onClick={closeMenu}
              >
                Portfolio
              </Link>
            </li>
            {/* <li>
              <Link 
                to="/pricing" 
                className={isActive('/pricing')} 
                onClick={closeMenu}
              >
                Pricing
              </Link>
            </li> */}
            <li>
              <Link 
                to="/contact" 
                className={isActive('/contact')} 
                onClick={closeMenu}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>

        {/* CTA buttons */}
        {/* <div className={`navbar-buttons ${isMenuOpen ? 'active' : ''}`}>
          <Link to="/login" className="navbar-login" onClick={closeMenu}>
            Log In
          </Link>
          <Link to="/signup" className="navbar-signup" onClick={closeMenu}>
            Sign Up
          </Link>
        </div> */}
      </div>
    </header>
  );
};

export default Navbar;

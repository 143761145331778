// src/pages/PrivacyPolicy.tsx
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: March 2024
        </Typography>
        <Typography variant="body1" paragraph>
          At Candorhouse Technologies, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your personal information.
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information that you voluntarily provide to us when you use our services, including:
          • Contact information (name, email, phone number)
          • Business information
          • Communication preferences
        </Typography>

        <Typography variant="h6" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect to:
          • Provide and improve our services
          • Communicate with you about our services
          • Respond to your inquiries
          • Comply with legal obligations
        </Typography>

        <Typography variant="h6" gutterBottom>
          Information Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement appropriate technical and organizational measures to protect your personal information.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us at privacy@candorhouse.com
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
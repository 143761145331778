import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactForm from  './pages/ContactForm';
import ClientTestimonials from './pages/ClientTestimonials';
import Biographies from './pages/Biographies';
import Portfolios from './pages/Portfolios';
import Careers from './pages/Careers';
import AdminInterface from './components/AdminInterface';
import Layout from './components/Layout/Layout';
import './App.css';
import { Container, Typography } from '@mui/material';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Home from './pages/Home/Home';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/testimonials" element={<ClientTestimonials />} />
          <Route path="/biographies" element={<Biographies />} />
          <Route path="/portfolios" element={<Portfolios />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/admin" element={<AdminInterface />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Container } from '@mui/material';
import ServicesCard from '../../components/ServicesCard';
import { fetchCoreServices, ICoreService } from '../../services/apiServices';
import './Home.css';

const Home: React.FC = () => {
  const [services, setServices] = useState<ICoreService[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadServices = async () => {
      try {
        setLoading(true);
        console.log('Home: Fetching core services');
        const data = await fetchCoreServices();
        
        // Enhanced debugging
        console.log('Home: Received data type:', typeof data);
        console.log('Home: Is data an array?', Array.isArray(data));
        console.log('Home: Data content:', data);
        
        // Extra validation
        if (!data) {
          console.error('Home: Data is null or undefined');
          throw new Error('No services data received');
        }
        
        if (!Array.isArray(data)) {
          console.error('Home: Data is not an array, received:', typeof data);
          throw new Error(`Expected array but got ${typeof data}`);
        }
        
        // Check if we got back actual data
        if (data.length > 0) {
          console.log('Home: Setting services with data of length', data.length);
          setServices(data);
          setError(null);
        } else {
          console.error('Home: Received empty array');
          throw new Error('No services data received (empty array)');
        }
      } catch (error) {
        console.error('Home: Failed to fetch services:', error);
        // Set a fallback array to prevent mapping errors
        setServices([]);
        setError('Unable to load services. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadServices();
  }, []);

  // Return early with a retry button if there's an error and no services
  if (error && services.length === 0) {
    return (
      <div className="home-page">
        <section className="hero-section">
          <div className="hero-content">
            <h1>Welcome to Candor House</h1>
            <p className="subtitle">Creating honest technical and automation solutions for your business opportunities.</p>
            <div className="hero-buttons">
              <Link to="/signup" className="button">Reach Out</Link>
            </div>
          </div>
        </section>

        <section className="features-section">
          <h2>How Candor House Helps</h2>
          <div className="error-message">
            {error}
            <button 
              onClick={() => window.location.reload()} 
              className="retry-button"
            >
              Retry
            </button>
          </div>
        </section>
        
        <section className="cta-section">
          <div className="cta-content">
            <h2>Ready to improve your business opportunities through technology?</h2>
            <p>Join others who are leveraging the technical expertise of Candor House Technoligies.</p>
            <Link to="/contact" className="button">Contact Us</Link>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Welcome to Candor House</h1>
          <p className="subtitle">Creating honest solutions for every business</p>
          <div className="hero-buttons">
            <Link to="/contact" className="button">Reach Out</Link>
          </div>
        </div>
      </section>

      {/* Features Section - Now with improved layout */}
      <section className="features-section">
        <h2>How Candor House Helps</h2>
        
        {loading ? (
          <div className="loading">Loading services...</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          <Container maxWidth="lg" className="services-container">
            <Grid 
              container 
              spacing={4}
              alignItems="stretch"
            >
              {Array.isArray(services) ? (
                services.map((service, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    key={service._id || `service-${service.title || 'unknown'}-${index}`}
                  >
                    <ServicesCard 
                      title={service.title || 'Service'}
                      icon={service.icon || 'Business'} 
                      items={Array.isArray(service.items) ? service.items : []}
                      _id={service._id || `fallback-${index}`}
                      index={index} 
                      total={services.length} 
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <div className="error-message">
                    Error: Invalid service data
                    <button 
                      onClick={() => window.location.reload()} 
                      className="retry-button"
                    >
                      Retry
                    </button>
                  </div>
                </Grid>
              )}
            </Grid>
          </Container>
        )}
      </section>

      {/* CTA Section */}
      <section className="cta-section">
        <div className="cta-content">
          <h2>Ready to improve your business communications?</h2>
          <p>Join others who are creating more meaningful business relationships.</p>
          <Link to="/contact" className="button">Contact Us</Link>
        </div>
      </section>
    </div>
  );
};

export default Home;

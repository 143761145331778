import React, { useState } from 'react';
import { Container, TextField, Button, Typography, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';

const AdminInterface: React.FC = () => {
  const [selectedPage, setSelectedPage] = useState<string>('');
  const [content, setContent] = useState<string>('');

  const handlePageChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    setSelectedPage(event.target.value);
  };

  const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Logic to save the content to the database
    console.log(`Saving content for ${selectedPage}: ${content}`);
  };

  return (
    <Container>
      <Typography variant="h1">Admin Interface</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="page-select-label">Select Page</InputLabel>
          <Select
            labelId="page-select-label"
            value={selectedPage}
            onChange={handlePageChange}
          >
            <MenuItem value="contact">Contact Form</MenuItem>
            <MenuItem value="testimonials">Client Testimonials</MenuItem>
            <MenuItem value="biographies">Biographies</MenuItem>
            <MenuItem value="portfolios">Portfolios</MenuItem>
            <MenuItem value="careers">Careers</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Content"
          fullWidth
          margin="normal"
          multiline
          rows={10}
          value={content}
          onChange={handleContentChange}
        />
        <Button variant="contained" color="primary" type="submit">Save</Button>
      </form>
    </Container>
  );
};

export default AdminInterface;
import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';

interface BioProps {
  name: string;
  title: string;
  bio: string;
  image: string;
}

const BioCard: React.FC<BioProps> = ({ name, title, bio, image }) => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        height="300"
        image={image}
        alt={name}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Typography gutterBottom variant="subtitle1" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {bio}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BioCard;
import React from 'react';
import { Card, CardContent, CardMedia, Typography, Chip, Box } from '@mui/material';
import { IPortfolio } from '../services/apiServices';

const PortfolioCard: React.FC<IPortfolio> = ({ title, description, image, link, technologies, date }) => {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        height="200"
        image={image || '/placeholder.jpg'}
        alt={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          {description}
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 2 }}>
          {technologies.map((tech) => (
            <Chip key={tech} label={tech} size="small" />
          ))}
        </Box>
        {link && (
          <Typography variant="body2" color="primary">
            <a href={link} target="_blank" rel="noopener noreferrer">
              View Project
            </a>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default PortfolioCard;
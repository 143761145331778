import React from 'react';
import { Container, Typography } from '@mui/material';

const ClientTestimonials: React.FC = () => {
  return (
    <Container>
      <Typography variant="h1">Client Testimonials</Typography>
      <Typography variant="body1">This is the Client Testimonials page.</Typography>
    </Container>
  );
};

export default ClientTestimonials;
import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, CircularProgress, Alert, Button } from '@mui/material';
import BioCard from '../components/BioCard';
import { getBiographies, IBiography } from '../services/apiServices';

// Fallback data for when the API fails
const fallbackBiographies: IBiography[] = [
  {
    _id: 'fallback-1',
    name: 'Daniel McCarthy',
    title: 'Co-Founder & Chief Technology Officer',
    bio: 'Daniel specializes in cloud infrastructure, automation, and security. With over a decade of experience in educational technology and enterprise solutions, he leads our technical vision.',
    image: '/images/daniel.jpg',
    links: []
  },
  {
    _id: 'fallback-2',
    name: 'Jacob Dean',
    title: 'Co-Founder & Chief Strategy Officer',
    bio: 'Jacob has extensive experience in DevOps, software architecture, and technical leadership. He guides our project execution with a focus on future-proof solutions.',
    image: '/images/jacob.jpg',
    links: []
  },
  {
    _id: 'fallback-3',
    name: 'Holly Bernstein',
    title: 'Chief Operating Officer',
    bio: 'Holly brings a wealth of experience in operations, process optimization, and client management. She ensures our projects are delivered on time and to the highest standards.',
    image: '/images/holly.jpg',
    links: []
  }
];

const Biographies: React.FC = () => {
  const [biographies, setBiographies] = useState<IBiography[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchBiographies = async () => {
    try {
      setLoading(true);
      console.log('Biographies: Fetching team data');
      const data = await getBiographies();
      
      // Enhanced debugging
      console.log('Biographies: Received data type:', typeof data);
      console.log('Biographies: Is data an array?', Array.isArray(data));
      
      // Extra validation
      if (!data) {
        console.error('Biographies: Data is null or undefined');
        throw new Error('No biography data received');
      }
      
      if (!Array.isArray(data)) {
        console.error('Biographies: Data is not an array, received:', typeof data);
        // Use fallback data instead of throwing
        console.log('Biographies: Using fallback data');
        setBiographies(fallbackBiographies);
        setError('Using backup team data');
        return;
      }
      
      // Check if we got back actual data
      if (data.length > 0) {
        console.log('Biographies: Setting team data with', data.length, 'members');
        setBiographies(data);
        setError(null);
      } else {
        console.warn('Biographies: Received empty array');
        setBiographies(fallbackBiographies);
        setError('Using backup team data (API returned empty)');
      }
    } catch (err) {
      console.error('Biographies: Failed to fetch team:', err);
      setBiographies(fallbackBiographies);
      setError('Unable to load team data from server. Showing backup data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBiographies();
  }, []);

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h2" gutterBottom align="center" sx={{ my: 4 }}>
        Our Team
      </Typography>
      
      {error && (
        <Alert 
          severity="warning" 
          sx={{ mb: 3 }}
          action={
            <Button color="inherit" size="small" onClick={() => fetchBiographies()}>
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      )}
      
      <Grid container spacing={4}>
        {Array.isArray(biographies) ? (
          biographies.map((member) => (
            <Grid 
              item 
              xs={12} 
              md={4} 
              key={member._id || `bio-${member.name}`}
            >
              <BioCard 
                name={member.name || 'Team Member'} 
                title={member.title || 'Candor House'} 
                bio={member.bio || 'Biography coming soon.'} 
                image={member.image || '/images/placeholder.jpg'} 
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Alert severity="error">
              Invalid data format received. Please try again later.
            </Alert>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Biographies;
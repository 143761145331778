// src/pages/Portfolios.tsx
import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, CircularProgress, Alert, Button } from '@mui/material';
import { getPortfolios, IPortfolio } from '../services/apiServices';
import PortfolioCard from '../components/PortfolioCard';

// Fallback portfolio data
const fallbackPortfolios: IPortfolio[] = [
  {
    _id: 'fallback-1',
    title: 'Network Operations Platform',
    description: 'A comprehensive network monitoring and management platform for a major telecommunications provider.',
    image: '/images/portfolio/networkops.png',
    link: '#',
    technologies: ['React', 'Node.js', 'MongoDB', 'Docker'],
    date: new Date('2023-09-15')
  },
  {
    _id: 'fallback-2',
    title: 'Veterinary Clinic Management System',
    description: 'End-to-end practice management solution for veterinary clinics with scheduling, billing, and patient records.',
    image: '/images/portfolio/vetclinic.png',
    link: '#',
    technologies: ['Angular', 'Firebase', 'TypeScript', 'Material UI'],
    date: new Date('2023-06-20')
  }
];

const Portfolios: React.FC = () => {
  const [portfolios, setPortfolios] = useState<IPortfolio[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPortfolios = async () => {
    try {
      setLoading(true);
      console.log('Portfolios: Fetching portfolio data');
      const data = await getPortfolios();
      
      // Enhanced debugging
      console.log('Portfolios: Received data type:', typeof data);
      console.log('Portfolios: Is data an array?', Array.isArray(data));
      
      // Extra validation
      if (!data) {
        console.error('Portfolios: Data is null or undefined');
        throw new Error('No portfolio data received');
      }
      
      if (!Array.isArray(data)) {
        console.error('Portfolios: Data is not an array, received:', typeof data);
        // Use fallback data instead of throwing
        console.log('Portfolios: Using fallback data');
        setPortfolios(fallbackPortfolios);
        setError('Using backup portfolio data');
        return;
      }
      
      // Check if we got back actual data
      if (data.length > 0) {
        console.log('Portfolios: Setting portfolio data with', data.length, 'items');
        setPortfolios(data);
        setError(null);
      } else {
        console.warn('Portfolios: Received empty array');
        setPortfolios(fallbackPortfolios);
        setError('Using backup portfolio data (API returned empty)');
      }
    } catch (err: any) {
      console.error('Portfolios: Failed to fetch portfolio:', err);
      setPortfolios(fallbackPortfolios);
      setError('Unable to load portfolio data from server. Showing backup data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPortfolios();
  }, []);

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h2" gutterBottom align="center" sx={{ my: 4 }}>
        Our Portfolio
      </Typography>
      
      {error && (
        <Alert 
          severity="warning" 
          sx={{ mb: 3 }}
          action={
            <Button color="inherit" size="small" onClick={() => fetchPortfolios()}>
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      )}
      
      <Grid container spacing={4}>
        {Array.isArray(portfolios) ? (
          portfolios.map((portfolio) => (
            <Grid 
              item 
              xs={12} 
              md={6} 
              key={portfolio._id || `portfolio-${portfolio.title}`}
            >
              <PortfolioCard 
                _id={portfolio._id || `fallback-${Math.random()}`}
                title={portfolio.title || 'Project'}
                description={portfolio.description || 'Project details coming soon.'}
                image={portfolio.image || '/images/portfolio/placeholder.png'}
                link={portfolio.link || '#'}
                technologies={Array.isArray(portfolio.technologies) ? portfolio.technologies : []}
                date={portfolio.date || new Date()}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Alert severity="error">
              Invalid data format received. Please try again later.
            </Alert>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Portfolios;
import axios from 'axios';

// Use localhost when accessing from browser
const API_URL = process.env.NODE_ENV === 'development' 
  ? '/api'
  : process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'any'
  }
});

// Add response interceptor for better error handling
api.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      message: error.message
    });
    return Promise.reject(error);
  }
);

// Interfaces
export interface ITestimonial {
  _id: string;
  name: string;
  company: string;
  position: string;
  message: string;
  date: Date;
}

export interface IBiography {
  _id: string;
  name: string;
  title: string;
  bio: string;
  image: string;
  links: string[];
}

export interface IPortfolio {
  _id: string;
  title: string;
  description: string;
  image: string;
  link: string;
  technologies: string[];
  date: Date;
}

export interface ICareer {
  _id: string;
  title: string;
  description: string;
  requirements: string[];
  location: string;
  type: string;
  postedDate: Date;
  applicationLink: string;
}

// Interface for core services data structure
export interface ICoreService {
  _id?: string;  // Make _id optional in case it comes from different sources
  title: string;
  icon: string;
  items: string[];
}

// API Services

// Testimonials CRUD
export const getTestimonials = async (): Promise<ITestimonial[]> => {
  const response = await axios.get(`${API_URL}/testimonials`);
  return response.data;
};

export const createTestimonial = async (testimonial: Omit<ITestimonial, '_id'>): Promise<ITestimonial> => {
  const response = await axios.post(`${API_URL}/testimonials`, testimonial);
  return response.data;
};

export const updateTestimonial = async (id: string, testimonial: Partial<ITestimonial>): Promise<ITestimonial> => {
  const response = await axios.put(`${API_URL}/testimonials/${id}`, testimonial);
  return response.data;
};

export const deleteTestimonial = async (id: string): Promise<void> => {
  await axios.delete(`${API_URL}/testimonials/${id}`);
};

// Biographies CRUD
export const getBiographies = async (): Promise<IBiography[]> => {
  const response = await axios.get(`${API_URL}/biographies`);
  return response.data;
};

export const createBiography = async (biography: Omit<IBiography, '_id'>): Promise<IBiography> => {
  const response = await axios.post(`${API_URL}/biographies`, biography);
  return response.data;
};

export const updateBiography = async (id: string, biography: Partial<IBiography>): Promise<IBiography> => {
  const response = await axios.put(`${API_URL}/biographies/${id}`, biography);
  return response.data;
};

export const deleteBiography = async (id: string): Promise<void> => {
  await axios.delete(`${API_URL}/biographies/${id}`);
};

// Portfolios CRUD
export const getPortfolios = async (): Promise<IPortfolio[]> => {
  try {
    const response = await api.get('/portfolios');
    return response.data;
  } catch (error: any) {
    console.error('Portfolio fetch error details:', {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data
    });
    throw error;
  }
};

export const createPortfolio = async (portfolio: Omit<IPortfolio, '_id'>): Promise<IPortfolio> => {
  const response = await axios.post(`${API_URL}/portfolios`, portfolio);
  return response.data;
};

export const updatePortfolio = async (id: string, portfolio: Partial<IPortfolio>): Promise<IPortfolio> => {
  const response = await axios.put(`${API_URL}/portfolios/${id}`, portfolio);
  return response.data;
};

export const deletePortfolio = async (id: string): Promise<void> => {
  await axios.delete(`${API_URL}/portfolios/${id}`);
};

// Careers CRUD
export const getCareers = async (): Promise<ICareer[]> => {
  const response = await axios.get(`${API_URL}/careers`);
  return response.data;
};

export const createCareer = async (career: Omit<ICareer, '_id'>): Promise<ICareer> => {
  const response = await axios.post(`${API_URL}/careers`, career);
  return response.data;
};

export const updateCareer = async (id: string, career: Partial<ICareer>): Promise<ICareer> => {
  const response = await axios.put(`${API_URL}/careers/${id}`, career);
  return response.data;
};

export const deleteCareer = async (id: string): Promise<void> => {
  await axios.delete(`${API_URL}/careers/${id}`);
};

// Default fallback services in case of API failure
const fallbackServices: ICoreService[] = [
  {
    _id: 'fallback-1',
    title: "Open Communication",
    icon: "Security",
    items: [
      "Build stronger relationships through honest communication",
      "Transparent communication channels"
    ]
  },
  {
    _id: 'fallback-2',
    title: "Trust Building",
    icon: "Business",
    items: [
      "Establish trust with proven methods",
      "Honesty and accountability frameworks"
    ]
  },
  {
    _id: 'fallback-3',
    title: "Progress Tracking",
    icon: "SmartToy",
    items: [
      "Measure communication improvements",
      "Data-driven insights"
    ]
  },
  {
    _id: 'fallback-4',
    title: "Custom Solutions",
    icon: "Cloud",
    items: [
      "Tailored communication strategies",
      "Adaptive improvement plans"
    ]
  }
];

// CoreServices API functions with improved error handling and debugging
export const fetchCoreServices = async (): Promise<ICoreService[]> => {
  try {
    console.log('Fetching core services from URL:', `${api.defaults.baseURL}/core-services`);
    const response = await api.get('/core-services');
    
    // Log the raw response data for debugging
    console.log('Core services response data type:', typeof response.data);
    console.log('Core services response data:', response.data);
    
    // Make sure we're returning an array
    if (!Array.isArray(response.data)) {
      console.error('API returned non-array data for core services:', response.data);
      
      // If we got an object with a data property that's an array, use that
      if (response.data && typeof response.data === 'object' && Array.isArray(response.data.data)) {
        console.log('Found array in response.data.data, using that instead');
        return response.data.data;
      }
      
      console.log('Using fallback services due to non-array response');
      return fallbackServices;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Core services fetch error details:', {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data
    });
    
    // Return fallback data instead of throwing error
    console.log('Using fallback services data due to error');
    return fallbackServices;
  }
};

// Update getCoreServices to use the improved fetchCoreServices function
export const getCoreServices = async (): Promise<ICoreService[]> => {
  return fetchCoreServices();
};

export const createCoreService = async (service: Omit<ICoreService, '_id'>): Promise<ICoreService> => {
  const response = await axios.post(`${API_URL}/core-services`, service);
  return response.data;
};

export const updateCoreService = async (id: string, service: Partial<ICoreService>): Promise<ICoreService> => {
  const response = await axios.put(`${API_URL}/core-services/${id}`, service);
  return response.data;
};

export const deleteCoreService = async (id: string): Promise<void> => {
  await axios.delete(`${API_URL}/core-services/${id}`);
};
import React from 'react';
import { Container, TextField, Button } from '@mui/material';

const ContactForm: React.FC = () => {
  return (
    <Container>
      <h1>Contact Us</h1>
      <form>
        <TextField label="Name" fullWidth margin="normal" />
        <TextField label="Email" fullWidth margin="normal" />
        <TextField label="Message" fullWidth margin="normal" multiline rows={4} />
        <Button variant="contained" color="primary" type="submit">Submit</Button>
      </form>
    </Container>
  );
};

export default ContactForm;